<template>
  <el-dialog title="创建任务" width="1300px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <div class="f-bold m-b-10">生产产品</div>
      <vxe-grid
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn1"
        :data="selfDetail.list"
      >
        <!-- slot_default -->
        <template #default_ji_hua_kai_gong_date="{ row }">
          {{ row.ji_hua_kai_gong_date && row.ji_hua_kai_gong_date.slice(0, 10) }}
        </template>
        <template #default_ji_hua_wan_cheng_date="{ row }">
          {{ row.ji_hua_wan_cheng_date && row.ji_hua_wan_cheng_date.slice(0, 10) }}
        </template>
      </vxe-grid>
      <div class="f-bold m-y-10">所需物料</div>
      <vxe-grid
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :merge-cells="mergeCell"
        :columns="tableColumn2"
        :data="selfDetail.need_list"
      >
      </vxe-grid>
      <div class="f-bold m-y-10">创建任务(可上下拖动顺序)</div>
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :edit-rules="validRules"
        :columns="tableColumn3"
        :data="selfDetail.ren_wu_list"
      >
        <!-- edit -->
        <template #edit_ren_wu_name="{ row }">
          <el-input v-model="row.ren_wu_name" size="mini" placeholder="请输入" clearable />
        </template>
        <template #edit_ren_wu_specification="{ row }">
          <el-input v-model="row.ren_wu_specification" size="mini" placeholder="请输入" clearable />
        </template>
        <template #edit_ren_wu_unit="{ row }">
          <el-select
            v-model="row.ren_wu_unit"
            size="mini"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="(item, index) in unitList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
        </template>
        <template #edit_ren_wu_count="{ row }">
          <el-input v-model="row.ren_wu_count" size="mini" placeholder="请输入" clearable />
        </template>
        <template #edit_ren_wu_ji_hua_kai_gong_date="{ row }">
          <e-date-picker
            type="date"
            fontSize="11px"
            width="120px"
            placeholder="选择日期"
            clearable
            :value.sync="row.ren_wu_ji_hua_kai_gong_date"
          />
        </template>
        <template #edit_ren_wu_ji_hua_wan_gong_date="{ row }">
          <e-date-picker
            type="date"
            fontSize="11px"
            width="120px"
            placeholder="选择日期"
            clearable
            :value.sync="row.ren_wu_ji_hua_wan_gong_date"
          />
        </template>

        <template #edit_ren_wu_fu_ze_ren="{ row }">
          <el-select
            v-model="row.ren_wu_fu_ze_ren"
            size="mini"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="(item, index) in saleList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
        </template>
        <template #edit_ren_wu_bei_zhu="{ row }">
          <el-input v-model="row.ren_wu_bei_zhu" size="mini" placeholder="请输入" clearable />
        </template>

        <!-- 操作 -->
        <template #default_do="{ rowIndex }">
          <el-link class="m-r-10" type="success" @click="rowAdd">增行</el-link>
          <el-link
            v-if="selfDetail.ren_wu_list.length > 1"
            class="m-r-10"
            type="danger"
            @click="rowDelete(rowIndex)"
          >
            删行
          </el-link>
        </template>

        <!-- 数据为空 -->
        <template #empty>
          <div class="flex flex-center">
            <span class="f-s-14 c-gray-light">暂无数据</span>
            <el-link class="m-l-10" type="success" @click="rowAdd">增行</el-link>
          </div>
        </template>
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Sortable from 'sortablejs'
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      mergeCell: [],
      selfDetail: {
        data: {},
        list: [],
        need_list: [],
        ren_wu_list: []
      },
      tableColumn1: [
        {
          title: '工序',
          field: 'process_order_name',
          width: 60
        },
        {
          title: '品名',
          field: 'craft',
          width: 100
        },
        {
          title: '工艺',
          field: 'process_name',
          width: 100
        },
        {
          title: '材质',
          field: 'texture',
          width: 80
        },
        {
          title: '规格',
          field: 'specification',
          width: 100
        },
        {
          title: '单位',
          field: 'unit',
          width: 80
        },
        {
          title: '计划生产数量',
          field: 'cai_gou_stock_counts',
          width: 100
        },
        {
          title: '交货日期',
          field: 'cai_gou_jiao_huo_ri_qi',
          width: 80
        },
        {
          title: '计划开工日期',
          field: 'ji_hua_kai_gong_date',
          slots: { default: 'default_ji_hua_kai_gong_date' },
          width: 130
        },
        {
          title: '计划完工日期',
          field: 'ji_hua_wan_cheng_date',
          slots: { default: 'default_ji_hua_wan_cheng_date' },
          width: 130
        },
        {
          title: '工单负责人',
          field: 'cao_zuo_yuan',
          width: 100
        },
        {
          title: '业务员',
          field: 'saler_name'
        }
      ],
      tableColumn2: [
        {
          title: '物料号',
          field: 'wu_liao_code',
          isMerge: true,
          width: 60
        },
        {
          title: '品名',
          field: 'craft',
          isMerge: true,
          width: 100
        },
        {
          title: '工艺',
          field: 'process_name',
          isMerge: true,
          width: 100
        },
        {
          title: '材质',
          field: 'texture',
          isMerge: true,
          width: 80
        },
        {
          title: '规格',
          field: 'sales_specification',
          isMerge: true,
          width: 100
        },
        {
          title: '单位',
          field: 'unit',
          isMerge: true,
          width: 80
        },
        {
          title: '数量',
          field: 'ru_ku_stock_counts',
          width: 180
        },
        {
          title: '仓库',
          field: 'cang_ku'
        }
      ],
      tableColumn3: [
        {
          title: '任务顺序',
          type: 'seq',
          width: 60
        },
        {
          title: '任务名称',
          field: 'ren_wu_name',
          width: 280,
          slots: { edit: 'edit_ren_wu_name' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '规格',
          field: 'ren_wu_specification',
          width: 100,
          slots: { edit: 'edit_ren_wu_specification' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '单位',
          field: 'ren_wu_unit',
          width: 80,
          slots: { edit: 'edit_ren_wu_unit' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '任务数量',
          field: 'ren_wu_count',
          width: 180,
          slots: { edit: 'edit_ren_wu_count' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '计划开工日期',
          field: 'ren_wu_ji_hua_kai_gong_date',
          width: 130,
          slots: { edit: 'edit_ren_wu_ji_hua_kai_gong_date' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '计划完工日期',
          field: 'ren_wu_ji_hua_wan_gong_date',
          width: 130,
          slots: { edit: 'edit_ren_wu_ji_hua_wan_gong_date' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '任务负责人',
          field: 'ren_wu_fu_ze_ren',
          width: 100,
          slots: { edit: 'edit_ren_wu_fu_ze_ren' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '备注',
          field: 'ren_wu_bei_zhu',
          slots: { edit: 'edit_ren_wu_bei_zhu' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '操作',
          slots: { default: 'default_do' }
        }
      ],
      validRules: {
        ren_wu_name: [{ required: true }],
        ren_wu_count: [{ required: true }],
        ren_wu_fu_ze_ren: [{ required: true }]
      },
      unitList: [],
      saleList: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getUnitList()
    this.getSaleList()
    this.getDetailData()
    if (!this.selfDetail.ren_wu_list.length) this.rowAdd() // 数据为空，默认补一行
    this.rowDrop()
  },
  methods: {
    // 行拖拽
    rowDrop() {
      this.$nextTick(() => {
        let $table = this.$refs.refTable
        let $sortContent = $table.$el.querySelector('.body--wrapper>.vxe-table--body tbody')
        this.sortable = Sortable.create($sortContent, {
          handle: 'tr',
          animation: 150,
          onEnd: ({ newIndex, oldIndex }) => {
            let data = this.$util.deepCopy(this.selfDetail.ren_wu_list)
            let oldItem = this.$util.deepCopy(data[oldIndex])
            // 上移
            if (newIndex < oldIndex) {
              if (newIndex === 0) {
                data.splice(oldIndex, 1)
                data.unshift(oldItem) // 顶部
              } else {
                data.splice(oldIndex, 1)
                data.splice(newIndex, 0, oldItem)
              }
            }
            // 下移
            if (newIndex > oldIndex) {
              if (newIndex === data.length - 1) {
                data.push(oldItem) // 底部
                data.splice(oldIndex, 1)
              } else {
                data.splice(newIndex + 1, 0, oldItem)
                data.splice(oldIndex, 1)
              }
            }
            this.selfDetail.ren_wu_list = this.$util.deepCopy(data)
          }
        })
      })
    },
    // 获取单位列表
    getUnitList() {
      this.$api({
        method: 'post',
        url: '/admin/unitSetting/search'
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.name,
              val: item.name
            }
          })
          this.unitList = result
        }
      })
    },
    // 获取业务员列表
    getSaleList() {
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          filterData: {
            role_name: '业务员'
          },
          pageSize: this.$constant.pageMax
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.nick_name,
              val: item.nick_name,
              id: item.account_id
            }
          })
          this.saleList = result
        } else {
          return
        }
      })
    },
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/gongDan/buildRenWu',
        params: {
          cai_gou_stock_id: this.data.cai_gou_stock_id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let data = this.$util.deepCopy(res.data)
          data.list = [{ ...data.data }]
          this.selfDetail = this.$util.deepCopy(data)
          this.mergeCell = this.$tool.getMergeCellConfigList(
            this.selfDetail.need_list,
            this.tableColumn2,
            'wu_liao_code'
          )
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush() {
      let isPassRenWuName = this.selfDetail.ren_wu_list.every((item) => item.ren_wu_name)
      let isPassRenWuCount = this.selfDetail.ren_wu_list.every(
        (item) => !!Number(item.ren_wu_count)
      )
      let isPassRenWuFuZeRen = this.selfDetail.ren_wu_list.every((item) => item.ren_wu_fu_ze_ren)

      if (!isPassRenWuName) {
        this.$message.error('请输入任务名称')
        return
      }
      if (!isPassRenWuCount) {
        this.$message.error('任务数量必须>0')
        return
      }
      if (!isPassRenWuFuZeRen) {
        this.$message.error('请选择任务负责人')
        return
      }

      // 更新任务负责人id
      this.selfDetail.ren_wu_list.forEach((itemSt) => {
        this.saleList.forEach((itemNd) => {
          if (itemSt.ren_wu_fu_ze_ren === itemNd.key) {
            itemSt.ren_wu_fu_ze_ren_id = itemNd.id
          }
        })
      })

      this.$api({
        method: 'post',
        url: '/admin/gcvip/gongDan/saveOrUpdate',
        data: this.selfDetail
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 行-增行
    rowAdd() {
      let result = {
        ren_wu_name: '',
        ren_wu_specification: '',
        ren_wu_unit: '',
        ren_wu_count: '',
        ren_wu_ji_hua_kai_gong_date: '',
        ren_wu_ji_hua_wan_gong_date: '',
        ren_wu_fu_ze_ren: '',
        ren_wu_bei_zhu: ''
      }
      this.selfDetail.ren_wu_list.push(result)
    },
    // 行-删行
    rowDelete(index) {
      this.selfDetail.ren_wu_list.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped></style>
